import {Grid} from "@material-ui/core";
import qs from "qs";
import React from "react";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterDate, FilterSearch, FilterSelect, FilterSwitch} from "../../components/Filters";
import workPlanStatusChoices from "../../components/forms/choices/workPlanStatusChoices.json";
import PaperPanel from "../../components/PaperPanel";
import useBlockUI from "../../hooks/useBlockUI";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useSentinelListAPI} from "../../hooks/useSentinelAPI";
import useUser from "../../hooks/useUser";
import {WorkPlanTable} from "./WorkPlanComponents";
import {WorkPlanDialogForm} from "./WorkPlanForms";

const WorkPlans = (props) => {
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(1000);
  const [orderBy, setOrderBy] = React.useState("is_expedited");
  const [workPlanDialogIsOpen, setWorkPlanDialogIsOpen] = React.useState(false);

  const blockUI = useBlockUI();

  const user = useUser();

  const [workPlanFilterOptions, setWorkPlanFilterOption, clearWorkPlanFilterOption, clearAllWorkPlanFilterOptions] =
    useFilterOptions(["Status", "Search", "Start Date", "End Date", "Expedited"], {
      Status: {value: "submitted", label: "Submitted"},
    });

  const workPlanFilterParams = {
    q: workPlanFilterOptions.Search.value,
    status: workPlanFilterOptions.Status.value,
    date_min: workPlanFilterOptions["Start Date"].value,
    date_max: workPlanFilterOptions["End Date"].value,
    is_expedited: workPlanFilterOptions["Expedited"].value,
    page: page,
    page_size: pageSize,
  };

  const {query: workPlanQuery, create: createWorkPlan} = useSentinelListAPI(
    `safety/work-plans/?${qs.stringify(workPlanFilterParams)}&pagesize=10000`,
    {
      initialData: {results: []},
    }
  );

  const workPlans = workPlanQuery.data.results;

  return (
    <>
      <PaperPanel>
        <PaperPanel.Header isLoading={workPlanQuery.isFetching}>
          <PaperPanel.Header.Title>Work Plans</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton
                onClick={() => {
                  setWorkPlanDialogIsOpen(true);
                }}
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.RefreshButton
                onClick={() => workPlanQuery.refetch()}
                isFetching={workPlanQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>

        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={3} xs={12}>
              <FilterSearch
                label="Search"
                value={workPlanFilterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setWorkPlanFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FilterSelect
                label="Status"
                name="Status"
                options={workPlanStatusChoices}
                value={workPlanFilterOptions["Status"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setWorkPlanFilterOption("Status", value, label);
                }}
                showStatusIcon
                statusType="workPlan"
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <FilterDate
                name="Start Date"
                value={workPlanFilterOptions["Start Date"].value}
                label="Start Date"
                onChange={(date) => {
                  setPage(1);
                  setWorkPlanFilterOption("Start Date", date, date);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FilterDate
                name="End Date"
                value={workPlanFilterOptions["End Date"].value}
                label="End Date"
                onChange={(date) => {
                  setPage(1);
                  setWorkPlanFilterOption("End Date", date, date);
                }}
                disableFuture={false}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FilterSwitch
                name="Expedited"
                label="Expedited"
                checked={Boolean(workPlanFilterOptions["Expedited"].value)}
                onChange={(value) => {
                  if (value) {
                    setWorkPlanFilterOption("Expedited", value, "Yes");
                  } else {
                    clearWorkPlanFilterOption("Expedited");
                  }
                  setPage(1);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FilterOptionChips
                filterOptions={workPlanFilterOptions}
                onDelete={clearWorkPlanFilterOption}
                onDeleteAll={() => clearAllWorkPlanFilterOptions()}
              />
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <WorkPlanTable workPlans={workPlans} />
        </PaperPanel.Body>
      </PaperPanel>

      <WorkPlanDialogForm
        isNew
        isOpen={workPlanDialogIsOpen}
        handleClose={() => setWorkPlanDialogIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Saving Work Plan...");
          createWorkPlan.mutateAsync(values).then((result) => {
            window.location.href = result.url;
          });
        }}
        initialValues={{
          created_by: user,
        }}
        selectProject
      />
    </>
  );
};

export default WorkPlans;
